import React, { useState, useEffect } from "react";
import { Link } from "gatsby";
import IconButton from '@material-ui/core/IconButton';
import Fade from '@material-ui/core/Fade';
import { IoMenu } from "@react-icons/all-files/io5/IoMenu";
import { IoClose } from "@react-icons/all-files/io5/IoClose";

import { MENU_OPTIONS } from "../utils/menu";
import useWindowWidth from "../utils/useWindowWidth";
import styles from "../styles/header.module.scss";
import sidebarStyles from "../styles/sidebar.module.scss";

export default function Header() {
  const { width } = useWindowWidth();
  const [ drawerOpen, setDrawerOpen ] = useState(false);

  // If drawer is open and width is greater than 768, close the drawer
  useEffect(() => {
    if (width > 768 && drawerOpen) {
      setDrawerOpen(false);
    }
  }, [width, drawerOpen]);

  return (
    <div className={ styles.header }>
      <IconButton onClick={() => setDrawerOpen(!drawerOpen)}>
        { drawerOpen 
          ? <IoClose className={ `${ styles.header__icon } icon__2px__down` }  /> 
          : <IoMenu className={ `${ styles.header__icon } icon__2px__down` }  />
        }
      </IconButton>
      
      <h1 className={ `${ styles.header__text } header` }>Amy Sitwala </h1>

      <Fade in={drawerOpen}>
        <div className={ styles.header__drawer }>
          { MENU_OPTIONS.map((option) => {
            return (
              <Link to={option.route} onClick={ () => setDrawerOpen(false) }
                key={option.name}
                className={ `${ sidebarStyles.sidebar__item } ${ styles.header__menu__item }` } 
                activeClassName={ sidebarStyles.sidebar__itemActive } 
                partiallyActive={ option.partiallyActive }>
                <span className={ `${ sidebarStyles.sidebar__bullet } ${ styles.header__menu__bullet }`}/>
                {option.name}
              </Link>
            )
          })}
        </div>
      </Fade>
    </div>
  );
};