export const MENU_OPTIONS = [
  {
    name: "Home",
    route: "/",
    partiallyActive: false,
  },
  {
    name: "About",
    route: "/about",
    partiallyActive: true,
  },
  {
    name: "Experience",
    route: "/experience",
    partiallyActive: true,
  },
  {
    name: "Portfolio",
    route: "/portfolio",
    partiallyActive: true,
  },
  {
    name: "Testimonials",
    route: "/testimonials",
    partiallyActive: true,
  },
  {
    name: "Resume",
    route: "/resume",
    partiallyActive: true,
  },
  {
    name: "Contact",
    route: "/contact",
    partiallyActive: true,
  },
];