import { useState, useEffect } from "react";

function getWindowWidth() {
  const windowExists = typeof window !== "undefined";
  return windowExists ? { width: window.innerWidth } : { width: 1200 };
}

export default function useWindowWidth() {
  
  const [windowWidth, setWindowWidth] = useState(getWindowWidth());

  useEffect(() => {
    function handleResize() {
      setWindowWidth(getWindowWidth());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowWidth;
}