import React from "react";
import { Helmet } from "react-helmet"
import Header from "./header";
import Sidebar from "./sidebar";
import Footer from "./footer";
import styles from "../styles/layout.module.scss";

export default function Layout({ children }) {
  return (
    <div className={ styles.layout }>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Amy Sitwala</title>
        <link rel="canonical" href="https://amysitwala.com" />
      </Helmet>
      <Header />
      <Sidebar />
      <div className={ styles.layout__main }>
        { children }
        <Footer />
      </div>
    </div>
  );
};
