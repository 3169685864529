import React from "react";
import { Link } from "gatsby";
import { MENU_OPTIONS } from "../utils/menu";
import styles from "../styles/sidebar.module.scss";

export default function Sidebar({ onClick = (name) => {} }) {
  return (
    <div className={ styles.sidebar }>
      <h1 className={ `${styles.sidebar__header} header` }>Amy <br/> Sitwala</h1>
      
      { MENU_OPTIONS.map((option) => {
        return (
          <Link 
            key={option.name}
            to={option.route} 
            className={ styles.sidebar__item } 
            activeClassName={ styles.sidebar__itemActive } 
            partiallyActive={option.partiallyActive}
            onClick={() => onClick(option.name)}
          >
            <span className={ `${styles.sidebar__bullet}`}/>
            {option.name}
          </Link>
        )
      })}
    </div>
  );
};