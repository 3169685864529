import React from "react";
import styles from "../styles/footer.module.scss";

export default function Footer() {
  const currentYear = new Date().getFullYear();
  return (
    <div className={ styles.footer }>
      <b>&copy; Amy Sitwala { currentYear }</b>. <span className={ styles.footer__tools}>Built from scratch with Gatsby.js</span>
    </div>
  );
};